import Swiper from "swiper";
import { Navigation, EffectFade, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

require("fslightbox");

const sliderImages = document.querySelectorAll(".js-fb-slider");

if (sliderImages) {
    sliderImages.forEach((slider) => {
        const sliderID = slider.dataset.id;
        const swiperOptions = {
            modules: [Navigation, EffectFade, Autoplay],
            navigation: {
                nextEl: `.js-fb-slider-next[data-target="${sliderID}"]`,
                prevEl: `.js-fb-slider-prev[data-target="${sliderID}"]`,
                disabledClass: "fb-slider__button--disabled",
            },
            autoplay: {
                delay: 5000,
            },
            spaceBetween: 0,
            slidesPerView: 1,
            effect: "fade",
        };

        const swiper = new Swiper(slider, swiperOptions);
    });
}
