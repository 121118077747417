import axios from "axios";

export const navigationDropdown = () => {
    const navigationDropdownElement = document.querySelector(
        ".js-pzt-navigation-dropdown"
    );
    const navigationListElement = document.querySelector(
        ".js-pzt-navigation-list"
    );
    if (navigationDropdownElement)
        navigationDropdownElement.addEventListener("click", (event) => {
            navigationListElement.classList.toggle(
                "pzt__navigation-list--expand"
            );
            navigationDropdownElement.classList.toggle(
                "pzt__navigation-dropdown--expand"
            );
        });
};

export const loadSvg = async () => {
    const progressBarElement = document.querySelector(".js-pzt-progress-bar");
    const svgContainerElement = document.querySelector(".js-pzt-svg-container");

    if (svgContainerElement) {
        try {
            const response = await axios.get(
                "/wp-content/themes/falebaltyku/acf-blocks/pzt/images/FB-pzt.svg",
                {
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.floor(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                        updateProgressBar(progressBarElement, percentCompleted);
                    },
                }
            );

            svgContainerElement.innerHTML = response.data; // Wstawienie SVG do kontenera
            updateProgressBar(progressBarElement, 100);
            setTimeout(fadeOutProgressBar, 200);

            // Dodaj obsługę zdarzeń po załadowaniu SVG
            setupSvgPathHover();
            setupListHover();
        } catch (error) {
            handleError(progressBarElement, error);
        }
    }
};

const updateProgressBar = (progressBar, percent, text = `${percent}%`) => {
    const progressBarElement = document.querySelector(".js-pzt-progress-bar");
    const progressTextElement = document.querySelector(".js-pzt-progress-text");
    const offset = 100 - percent;

    progressBarElement.style.strokeDashoffset = offset;
    progressTextElement.textContent = text;
};

const fadeOutProgressBar = () => {
    const progressContainer = document.querySelector(
        ".pzt__progress-container"
    );
    progressContainer.style.opacity = "0";
    setTimeout(() => {
        progressContainer.style.display = "none";
    }, 1000); // Match this duration to the CSS transition duration
};

const handleError = (progressBar, error) => {
    if (error.response) {
        updateProgressBar(
            progressBar,
            0,
            `Błąd ładowania: ${error.response.status}`
        );
    } else if (error.request) {
        updateProgressBar(progressBar, 0, "Błąd połączenia");
    } else {
        updateProgressBar(progressBar, 0, `Błąd: ${error.message}`);
    }
};

const setupSvgPathHover = () => {
    const pathElements = document.querySelectorAll(".js-pzt-path");
    const svgImage = document.querySelector(".js-pzt-svg-image");

    pathElements.forEach((path) => {
        const id = path.getAttribute("id");
        const activeBuilding = document.querySelector(
            `.js-pzt-hover-target[data-id="${id}"]`
        );

        path.addEventListener("mouseenter", () => {
            handleMouseEnterPath(path, svgImage);
        });

        path.addEventListener("mouseleave", () => {
            handleMouseLeavePath(svgImage, activeBuilding);
        });

        path.addEventListener("mousemove", (event) => {
            handleMouseMovePath(activeBuilding, event);
        });

        path.addEventListener("click", () => {
            handleClickPath(id);
        });
    });
};

const setupListHover = () => {
    const listItemsElements = document.querySelectorAll(
        ".js-pzt-navigation-link"
    );

    listItemsElements.forEach((item) => {
        item.addEventListener("mouseenter", (event) => {
            handleMouseEnterListItem(item, event);
        });

        item.addEventListener("mouseleave", () => {
            handleMouseLeaveListItem(item);
        });

        item.addEventListener("mousemove", (event) => {
            handleMouseMoveListItem(item, event);
        });
    });
};

const handleMouseEnterPath = (path, svgImage) => {
    const id = path.id;
    const activeBuilding = document.querySelector(
        `.js-pzt-hover-target[data-id="${id}"]`
    );
    const listItem = document.querySelector(
        `.js-pzt-navigation-link[data-id="${id}"]`
    );
    if (activeBuilding) {
        activeBuilding.style.display = "block";
        activeBuilding.style.position = "absolute";
        activeBuilding.style.pointerEvents = "none";
    }
    if (listItem) {
        listItem.classList.add("pzt__navigation-link--highlight");
    }
    svgImage.style.opacity = "0.5";
    svgImage.style.transition = "0.3s";
};

const handleMouseLeavePath = (svgImage, activeBuilding) => {
    const id = activeBuilding ? activeBuilding.dataset.id : null;
    const listItem = document.querySelector(
        `.js-pzt-navigation-link[data-id="${id}"]`
    );

    svgImage.style.opacity = "1";
    if (activeBuilding) {
        activeBuilding.style.display = "none";
    }
    if (listItem) {
        listItem.classList.remove("pzt__navigation-link--highlight");
    }
};

const handleMouseMovePath = (
    activeBuilding,
    event,
    offsetX = 40,
    offsetY = -50
) => {
    if (activeBuilding) {
        activeBuilding.style.left = `${event.offsetX + offsetX}px`;
        activeBuilding.style.top = `${event.offsetY + offsetY}px`;
    }
};

const handleClickPath = (id) => {
    const building = document.querySelector(
        `.js-pzt-hover-target[data-id="${id}"]`
    );
    if (building) {
        const link = building.querySelector(".pzt__building-link");
        if (link) {
            window.location.href = link.href;
        }
    }
};

const handleMouseEnterListItem = (item, event) => {
    const id = item.getAttribute("data-id");
    const path = document.getElementById(id);
    const building = document.querySelector(
        `.js-pzt-hover-target[data-id="${id}"]`
    );
    const svgImage = document.querySelector(".js-pzt-svg-image");
    if (path) {
        path.classList.add("pzt__svg-building--highlight");
    }
    if (building) {
        building.style.display = "block";
        building.style.position = "absolute";
        building.style.pointerEvents = "none";
    }
    svgImage.style.opacity = "0.5";
    svgImage.style.transition = "0.3s";
};

const handleMouseLeaveListItem = (item) => {
    const id = item.getAttribute("data-id");
    const path = document.getElementById(id);
    const building = document.querySelector(
        `.js-pzt-hover-target[data-id="${id}"]`
    );
    const svgImage = document.querySelector(".js-pzt-svg-image");
    if (path) {
        path.classList.remove("pzt__svg-building--highlight");
    }
    if (building) {
        building.style.display = "none";
    }
    svgImage.style.opacity = "1";
};

const handleMouseMoveListItem = (item, event) => {
    const id = item.getAttribute("data-id");
    const building = document.querySelector(
        `.js-pzt-hover-target[data-id="${id}"]`
    );
    if (building) {
        handleMouseMovePath(building, event, 300, 100);
    }
};
